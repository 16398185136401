/**
 * Single page pay link checkout
 * Used for Fixed, Custom amount, Single-select list pay links
 */

import { useContext, useRef, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import { Configs } from "../../helpers/context";
import {
  getWalletCollectOptions,
  getEcommerceTransactionOptions,
  WALLET_COLLECT_OPTIONS,
  validateOrder,
} from "../../helpers/collect";
import { formatIntegerCurrencyDecimal } from "../../helpers/formatters";
import { showApplePayButton } from "../../helpers/applePay";
import { EMIT_SUCCESS } from "../../helpers/event-definitions";
import { getOrderTotal } from "../../helpers/order";

import { charge } from "../../rest/charge";

const WalletCollect = ({
  payLink,
  order,
  product,
  setTransaction,
  onCollectError,
  showError,
  setCharging,
  trackCustomEvent,
}) => {
  const configsContext = useContext(Configs);
  const [isMounted, setIsMounted] = useState(false);
  // global wallet support status
  // used to verify we've checked for all supported wallets before mounting
  const [isSupported, setIsSupported] = useState(false);

  // use a ref for permanent component state
  // that shouldn't trigger rerender on change
  const collectRef = useRef();

  const startSession = (source, walletUpdate) => {
    if (source === 'google_pay') {
      collectRef?.current?.startGooglePaySession(walletUpdate);
      return;
    }

    collectRef?.current?.startApplePaySession(walletUpdate);
  }

  /**
   * Triggered when payment is authorized (aka nonce is ready)
   * @param {Event} event
   */
  async function onPaymentAuthorized(e) {
    try {
      // if nonce not returned, fail
      if (!e.nonce) {
        throw new Error();
      }

      showError(false);
      setCharging(true);

      // attach businessId
      e.businessId = configsContext?.configs?.businessId;

      // create transaction

      const transaction = await charge(configsContext?.configs?.env, {
        ...getEcommerceTransactionOptions(configsContext?.configs, payLink, order, product, e),
        nonce: e.nonce,
        emailAddress: e.shippingAddress?.emailAddress || e.billingAddress?.emailAddress,
        phone: e.shippingAddress?.phoneNumber || e.billingAddress?.phoneNumber,
      });

      // Metrics.track(MetricEvents.ORDER_COMPLETED_POYNT_APPLE_PAY_IMPRESSION);
      e.complete();

      setCharging(false);
      setTransaction(transaction);

      if (trackCustomEvent) {
        trackCustomEvent(EMIT_SUCCESS);
      }
      route("/transaction-complete");
    } catch (error) {
      console.log("payment authorize error", error);

      const walletError = {
        code: "invalid_payment_data",
        message: "Unable to process payment",
      };

      e.complete({ error: walletError });

      setCharging(false);

      showError("Card is invalid. Please try again.");
    }
  }

  /**
   * Triggers on wallet pay button click
   * @param {Event} event
   */
  function onWalletPayClick(e) {
    const walletUpdate = {
      total: {
        label: "TOTAL",
        amount: formatIntegerCurrencyDecimal(getOrderTotal(order)),
      },
    };

    const error = validateOrder(configsContext?.configs, payLink, order);
    if (error) {
      showError(error);
      throw new Error(error);
    }

    try{
      startSession(e.source, walletUpdate);
    } catch (error) {
      console.log(`session error - ${e.source}`, error);
    }
  }

  /**
   * Triggers on shipping address/method change 
   * @param {Event} event
   */
  function onShippingAddressChange(e) {
    const options = {
      shippingMethods: [],
    }

    e.updateWith(options);
  }

  useEffect(async () => {
    const collect = new TokenizeJs(
      configsContext?.configs?.businessId,
      payLink.applicationId,
      getWalletCollectOptions(configsContext?.configs, payLink, order),
    ); // wallet pay collect

    collect.on("payment_authorized", onPaymentAuthorized);
    collect.on("shipping_address_change", onShippingAddressChange);
    collect.on("error", onCollectError);

    collectRef.current = collect;

    // collect.on("wallet_button_click", (e) => {
    //   // later will add events for 'google_pay'
    //   if (e.source === "apple_pay") {
    //     console.log("e", e);
    //     // Metrics.track(MetricEvents.COMPLETE_ORDER_POYNT_APPLE_PAY_CLICK);
    //   }
    // });

    try {
      // get what wallet providers are supported
      const result = await collect.supportWalletPayments();

      // update global config with supportGooglePay since other pages use it for styling
      if (result?.googlePay) {
        configsContext?.setConfig("supportGooglePay", true);
        WALLET_COLLECT_OPTIONS?.paymentMethods.push("google_pay");
      }

      // check if we can show the apple pay button (eg merchant is registered))
      const show = await showApplePayButton(
        configsContext?.configs?.env,
        configsContext?.configs?.businessId,
      );

      if (show.showApplePay) {
        if (result?.applePay) {
          // update global config with supportApplePay since other pages use it for styling
          configsContext?.setConfig("supportApplePay", true);
          WALLET_COLLECT_OPTIONS.paymentMethods.push("apple_pay");
        }
      }
      setIsSupported(true);
    } catch (err) {
      // do nothing, just don't turn on apple pay
    }
  }, []);

  useEffect(() => {
    if (!isMounted &&
      collectRef.current &&
      (configsContext?.configs?.supportApplePay || configsContext?.configs?.supportGooglePay)) {
        collectRef.current.mount("wallet-payments-container", document, {
          ...WALLET_COLLECT_OPTIONS,
          buttonOptions: {
            ...WALLET_COLLECT_OPTIONS.buttonOptions,
            onClick: onWalletPayClick,
            type: (configsContext?.configs?.supportApplePay ? 'plain' : 'pay'),
          },
        });
      setIsMounted(true);
    }
  }, [isSupported, collectRef.current, isMounted]);

  return (configsContext?.configs?.supportApplePay || configsContext?.configs?.supportGooglePay) ? (
    <div id="wallet-payments-container" />
  ) : null;
};

export default WalletCollect;
