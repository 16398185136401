import { Fragment } from "preact";
import { useContext } from "preact/hooks";

import LegacyOrderSummaryItem from "./LegacyOrderSummaryItem";
import OrderSummaryLineItem from "./OrderSummaryLineItem";
import OrderSummaryAmounts from "./OrderSummaryAmounts";
import OrderSummaryBreakdown from "./OrderSummaryBreakdown";
import { Configs } from "../../helpers/context";

import "../../style/OrderSummary.css";

const OrderSummary = ({ order, header }) => {
  const configsContext = useContext(Configs);
  return (
    <div className="container order-summary">
      {header}
      {order?.isLegacy ? (
        <Fragment>
          <div class="order-summary-divider"></div>
          {order?.notes?.value ? (
            <Fragment>
              <div className="order-notes">{order.notes.value}</div>
              <div class="order-summary-divider"></div>
            </Fragment>
          ) : null}
          {order?.order?.v?.context?.sourceApp !== "co.poynt.invoicing" && (
            <div>
              {order?.order?.value?.items?.map((item) => {
                return <LegacyOrderSummaryItem item={item} />;
              })}
              <div class="order-summary-divider"></div>
            </div>
          )}
          <OrderSummaryAmounts
            discountTotal={order?.order?.value?.amounts?.discountTotal}
            feeTotal={order?.order?.value?.amounts?.feeTotal}
            taxTotal={order?.order?.value?.amounts?.taxTotal}
            subTotal={order?.order?.value?.amounts?.subTotal}
            tipAmount={order?.order?.value?.amounts?.tipAmount}
            total={order?.order?.value?.amounts?.transactionAmount}
          />
        </Fragment>
      ) : (
        <Fragment>
          {order?.order?.value?.showAdjustmentBreakdown ? 
            null 
            : <>
              <div class="order-summary-divider"></div>
              {order.notes ? (
                <Fragment>
                  <div className="order-notes">
                    {order.notes
                      .filter(note => note.authorType === "CUSTOMER" && note.content.trim() !== '')
                      .map((note, index) => (
                        <div key={index}>
                          <p>{note.content}</p>
                        </div>
                    ))}
                  </div>
                </Fragment>
              ) : null}
            </>
          }
          {/* For Central Orders, we don't store the notes in a signal. It's an object */}
          {order?.order?.value?.lineItems?.map((lineItem) => {
            return <OrderSummaryLineItem lineItem={lineItem} />;
          })}
          {order?.order?.value?.showAdjustmentBreakdown ?
            (<OrderSummaryBreakdown
              order={order}
              subTotal={order?.order?.value?.totals?.subTotal?.value}
              total={order?.order?.value?.totals?.transactionAmount}
              adjustments={order?.order?.value?.adjustments}
              tipAmount = {order?.order?.value?.totals?.tipAmount}
            />) :
            (<OrderSummaryAmounts
              discountTotal={order?.order?.value?.totals?.discountTotal?.value}
              feeTotal={order?.order?.value?.totals?.feeTotal?.value}
              taxTotal={order?.order?.value?.totals?.taxTotal?.value}
              subTotal={order?.order?.value?.totals?.subTotal?.value}
              shippingTotal={order?.order?.value?.totals?.shippingTotal?.value}
              tipAmount={order?.order?.value?.totals?.tipAmount}
              total={order?.order?.value?.totals?.transactionAmount}
            />)
          }
        </Fragment>
      )}
    </div>
  );
};

export default OrderSummary;
