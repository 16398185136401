import { useEffect } from "preact/hooks";
import { EventType } from "./../enums/event-type";

function usePostMessage(sender: string, action: EventType, data: any, callback?: () => void) {
  useEffect(() => {
    const message = { sender, action, data };

    console.log("Sending message to parent", JSON.stringify(message));

    window?.parent?.postMessage(message, "*");
  }, [callback, sender, action, data]);
}

export default usePostMessage;
