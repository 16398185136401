import { Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Text } from "preact-i18n";
import { translate } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

const OrderSummaryBreakdown = ({
    order,
    subTotal,
    total,
    adjustments,
    tipAmount,
}) => {
const configsContext = useContext(Configs);
let finalAdjustments = adjustments || [];

if (tipAmount) {
  finalAdjustments = [
    ...finalAdjustments,
    {
      name: translate('CHECKOUT_TIP', '', configsContext?.configs?.messages, {}, undefined, ""),
      value: formatIntegerCurrencyString(tipAmount, configsContext?.configs?.currency, configsContext?.configs?.ecommerceLocaleLanguage)
    }
  ];
}

return (
    <Fragment>
        {subTotal !== total ? (
        <Fragment>
          <div class="order-summary-divider"></div>
          <div className="order-subtotal">
            <span style="float:left;">
              <Text id="CHECKOUT_ITEM_TOTAL">Item Total</Text>
            </span>
            <span style="float:right;">
              {formatIntegerCurrencyString(
                subTotal,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </span>
            <div style="clear:both;"></div>
          </div>
        </Fragment>
      ) : null}
        {order.notes ? (
            <Fragment>
              <div className="order-notes">
                {order.notes
                  .filter(note => note.authorType === "CUSTOMER" && note.content.trim() !== '')
                  .map((note, index) => (
                    <div key={index}>
                      <p>{note.content}</p>
                    </div>
                ))}
              </div>
            </Fragment>
        ) : null}
        {finalAdjustments && finalAdjustments.length > 0 ?
        <div className="order-adjustments">
            {finalAdjustments?.map((adjustment) => {
                return (
                    <div className="order-adjustment-item">
                        <div className="order-adjustment-item-left">
                            <div className="order-adjustment-item-title">
                                <span style="float:left;">
                                    <Text id="adjustment-example-title">
                                        {adjustment.name}
                                    </Text>
                                </span>
                                {adjustment.details ? (
                                <Fragment>
                                    <br />
                                    <div className="order-adjustment-item-desc">
                                        <span style="float:left;">
                                            <Text id="adjustment-example-desc">
                                                {adjustment.details}
                                            </Text>
                                        </span>
                                    </div> 
                                </Fragment>
                                ) : null}
                            </div>
                        </div>
                        <div order-adjustment-item-right>
                            <span className="order-adjustment-item-value" style="float:right;">
                                {adjustment.value}
                            </span>
                        </div>
                    </div>
                )
            })}
        </div>
        : null}
        <div class="order-summary-divider"></div>
        <div className="order-total">
        <span style="float:left">
          <Text id="CHECKOUT_TOTAL">Total</Text>
        </span>
        <span style="float:right;">
          {formatIntegerCurrencyString(
            total,
            configsContext?.configs?.currency,
            configsContext?.configs?.ecommerceLocaleLanguage,
          )}
        </span>
        <div style="clear:both;"></div>
        </div>
    </Fragment>
)
}

export default OrderSummaryBreakdown;
