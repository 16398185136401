import { signal, computed } from "@preact/signals";

import { createLineItemSignal } from "./lineItem";
import { createShippingLineSignal } from "./shippingLine";
import { createShippingSignal } from "./shipping";
import { createBillingSignal } from "./billing";

import { FulfillmentMode } from "../enums/fulfillmentMode";
import { FulfillmentStatus } from "../enums/fulfillmentStatus";
import { OrderStatus } from "../enums/orderStatus";
import { PaymentStatus } from "../enums/paymentStatus";

import { getBreakdown } from "../helpers/order";

/**
 * Creates a signal from a unified order object
 * @param {Order} data
 * @returns
 */
export function createOrderSignal(data, configs) {
  const centralOrderId = signal(data?.id || "");
  const inventoryBehaviorForCompletedFulfilledOrders = signal(data?.inventoryBehaviorForCompletedFulfilledOrders || "BYPASS");
  const number = signal(data?.number || "");
  const numberDisplay = signal(data?.numberDisplay || "");
  const externalId = signal(data?.externalId || "");
  const cartId = signal(data?.cartId || "");

  const tip = signal(0);

  const fulfillmentMode = signal(data?.lineItems?.[0]?.fulfillmentMode || FulfillmentMode.NONE);
  const fulfillmentChannelId = signal(data?.lineItems?.[0]?.fulfillmentChannelId || "");

  const lineItems = signal(
    (data?.lineItems || []).map((item) => {
      return createLineItemSignal(item, configs);
    }),
  );

  const billing = signal(createBillingSignal(data?.billing || {}));

  const shipping = signal(createShippingSignal(data?.shipping || {}));

  const shippingLines = signal(
    (data?.shippingLines || []).map((item) => {i
      return createShippingLineSignal(item, configs);
    }),
  );

  const currencyCode = configs?.currency || data?.currency || "USD";

  let showAdjustmentBreakdown = true;

  let adjustments = {};


  const order = computed(() => {
    let subTotal = 0;

    (lineItems?.value || []).forEach((item) => {
      const lineItem = item?.lineItem?.value;
      subTotal += (lineItem?.quantity || 1) * (lineItem?.unitAmount?.value || 0);
    });

    const adjustmentsBreakdown = getBreakdown({
      lineItems: (lineItems?.value || []).map((lineItem) => lineItem?.lineItem?.value || {}),
      shippingLines: (shippingLines?.value || []).map((shippingLine) => shippingLine.shippingLine?.value || {}),
      nonSignalOrderData: data,
      i18nLocale: configs?.i18nLocale
    });

    adjustments = adjustmentsBreakdown.adjustments;

    let taxTotal = adjustmentsBreakdown.totals.taxTotal;
    let feeTotal = adjustmentsBreakdown.totals.feeTotal;
    let discountTotal = adjustmentsBreakdown.totals.discountTotal;
    let shippingTotal = adjustmentsBreakdown.totals.shippingTotal;

    // checks if calculations are wrong; if wrong default to order amounts
    // [DISCLAIMER] We only do this for more static Pay Link types! For Product Pay Links,
    // the user dynamically selects things like variant price, selected addons,
    // quantity, etc. All these change the `totals` on the Order, but the code only modifies
    // the individual line items and NOT the Order totals. For now that means that
    // it isn't safe to do these total checks for Pay Links that modify line item data!
    if (!configs.isProductPayLink) {
      if (taxTotal !== data?.totals?.taxTotal?.value) {
        console.warn(
          "Tax total calculated from order items does not match tax total from order amounts",
          taxTotal,
          data?.totals?.taxTotal?.value,
        );
        taxTotal = data?.totals?.taxTotal?.value;
        showAdjustmentBreakdown = false;
      } else if (feeTotal !== data?.totals?.feeTotal?.value) {
        console.warn(
          "Fee total calculated from order items does not match fee total from order amounts",
          feeTotal,
          data?.totals?.feeTotal?.value,
        );
        taxTotal = data?.totals?.feeTotal?.value;
        showAdjustmentBreakdown = false;
      } else if (discountTotal !== data?.totals?.discountTotal?.value) {
        console.warn(
          "Discount total calculated from order items does not match discount total from order amounts",
          discountTotal,
          data?.totals?.discountTotal?.value,
        );
        discountTotal = data?.totals?.discountTotal?.value;
        showAdjustmentBreakdown = false;
      } else if (shippingTotal !== data?.totals?.shippingTotal?.value) {
        console.warn(
          "Shipping total calculated from order items does not match shipping total from order amounts",
          shippingTotal,
          data?.totals?.shippingTotal?.value,
        );
        shippingTotal = data?.totals?.shippingTotal?.value;
        showAdjustmentBreakdown = false;
      } if (subTotal !== data?.totals?.subTotal?.value) {
        console.warn(
          "Subtotal calculated from order items does not match subtotal from order amounts",
          subTotal,
          data?.totals?.subTotal?.value,
        );
        subTotal = data?.totals?.subTotal?.value;
        showAdjustmentBreakdown = false;
      }
    }

    const total = subTotal + feeTotal - discountTotal + taxTotal + shippingTotal;

    const orderObject = {
      id: centralOrderId.value,
      number: number.value,
      numberDisplay: numberDisplay.value,
      context: {
        // Inside `src/lib/e-commerce.ts`, we pull the channelId and storeId from the created transaction
      },
      statuses: {
        // default to no fulfillment aka completed order upon payment
        fulfillmentStatus: data?.statuses?.fulfillmentStatus || FulfillmentStatus.FULFILLED,
        paymentStatus: data?.statuses?.paymentStatus || PaymentStatus.PAID,
        status: data?.statuses?.status || OrderStatus.COMPLETED,
      },
      shipping: shipping?.value?.shipping?.value || {},
      billing: billing?.value?.billing?.value || {}, // will be filled out on payment
      totals: {
        discountTotal: {
          value: discountTotal,
          currencyCode,
        },
        feeTotal: {
          value: feeTotal,
          currencyCode,
        },
        taxTotal: {
          value: taxTotal,
          currencyCode,
        },
        shippingTotal: {
          value: shippingTotal,
          currencyCode,
        },
        subTotal: {
          value: subTotal,
          currencyCode,
        },
        total: {
          value: total,
          currencyCode,
        },
        orderAmount: total,
        tipAmount: tip.value,
        transactionAmount: total + tip.value,
        currency: data?.currency || "USD",
      },
      lineItems: (lineItems?.value || []).map((lineItem) => {
        return lineItem?.lineItem?.value;
      }),
      shippingLines: (shippingLines?.value || []).map((shippingLine) => {
        return shippingLine.shippingLine?.value;
      }),
      taxes: data?.taxes,
      notes: data?.notes || [],
      inventoryBehaviorForCompletedFulfilledOrders: inventoryBehaviorForCompletedFulfilledOrders.value,
      adjustments: adjustments,
      showAdjustmentBreakdown: showAdjustmentBreakdown,
    };

    if (externalId.value) {
      orderObject.externalId = externalId.value;
    }

    if (cartId.value) {
      orderObject.cartId = cartId.value;
    }

    // // if order is a fulfillment order, set order status to open
    // if (fulfillmentMode.value !== FulfillmentMode.NONE) {
    //   orderObject.statuses.fulfillmentStatus = FulfillmentStatus.UNFULFILLED;
    //   orderObject.statuses.status = OrderStatus.OPEN;

    //   if (fulfillmentChannelId.value) {
    //     orderObject.lineItems.forEach((item) => {
    //       item.fulfillmentMode = fulfillmentMode.value;
    //       item.fulfillmentChannelId = fulfillmentChannelId.value;
    //       item.status = FulfillmentStatus.UNFULFILLED;
    //     });
    //   }
    // }

    return orderObject;
  });

  return {
    id: centralOrderId,
    number,
    numberDisplay,
    notes: data?.notes || [],
    lineItems,
    shipping,
    shippingLines,
    billing,
    order,
    tip,
    fulfillmentMode,
    fulfillmentChannelId,
    adjustments,
    showAdjustmentBreakdown,
  };
}
