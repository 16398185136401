import { useState, useEffect, useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { getCollectUrl } from "../../helpers/urls";
import { EMIT_COLLECT_ERROR } from "../../helpers/event-definitions";

import CardCollect from "./CardCollect";
import WalletCollect from "./WalletCollect";

import "../../style/PoyntCollectForm.css";

const PoyntCollectForm = ({
  order,
  payLink,
  product,
  setTransaction,
  setShowError,
  setErrorMsg,
  trackCustomEvent,
}) => {
  // use state for things that should trigger rerender
  const [collectLoaded, setCollectLoaded] = useState(false);
  const [charging, setCharging] = useState(false);

  const configsContext = useContext(Configs);

  // hook that runs as soon as businessId is defined
  // initializes the poynt collect component
  useEffect(() => {
    if (!configsContext?.configs?.businessId) {
      return;
    }

    const pc = document.createElement("script");
    pc.src = getCollectUrl(configsContext?.configs?.env);
    pc.async = true;
    pc.parentUrl = window.location.hostname;
    pc.onload = () => {
      setCollectLoaded(true);
    };
    document.getElementById("poynt-collect").appendChild(pc);
  }, [configsContext?.configs?.businessId]);

  /**
   * Triggered on Collect error
   * @param {Event} event
   */
  function onCollectError(event) {
    if (!event) {
      return;
    }

    if (trackCustomEvent) {
      trackCustomEvent(EMIT_COLLECT_ERROR, {
        data: JSON.stringify(event.data),
        errorSource: event.data?.error?.source,
        errorType: event.data?.error?.type,
        message: event.data?.message || event.data?.data?.message,
        developerMessage: event.data?.developerMessage || event.data?.data?.developerMessage,
        httpStatus: event.data?.httpStatus || event.data?.data?.httpStatus,
        requestId: event.data?.requestId || event.data?.data?.requestId,
      });
    }

    if (event.data?.error) {
      // validation for card input
      if (
        event.data.error.source === "field" ||
        event.data.error.type === "missing_fields" ||
        event.data.error.type === "invalid_details"
      ) {
        showError(event.data.error.message);
      }
      // don't show error on other error events
    } else {
      let errorMsg =
        event.data?.message ||
        event.data?.developerMessage ||
        event.data?.data?.message ||
        event.data?.data?.developerMessage ||
        (event.data?.httpStatus && `Request failed with status code ${event.data.httpStatus}`) ||
        "Error, please try again.";
      (event.data?.data?.httpStatus &&
        `Request failed with status code ${event.data.data.httpStatus}`) ||
        "Error, please try again.";

      if (event.data?.requestId) {
        errorMsg += ` (Request ID: ${event.data.requestId})`;
      } else if (event.data?.data?.requestId) {
        errorMsg += ` (Request ID: ${event.data.data.requestId})`;
      }

      showError(errorMsg);
    }
  }

  /**
   * Shows an error toast message
   * @param {string} message
   */
  function showError(message) {
    if (!message) {
      setShowError(false);
      setErrorMsg("");
    } else {
      setCharging(false);
      setShowError(true);
      setErrorMsg(message);
      setTimeout(() => {
        showError(false);
      }, 5000); // clear error message after 5 seconds
    }
  }

  return (
    <div>
      {collectLoaded ? (
        <WalletCollect
          payLink={payLink}
          order={order}
          product={product}
          setTransaction={setTransaction}
          onCollectError={onCollectError}
          showError={showError}
          charging={charging}
          setCharging={setCharging}
          trackCustomEvent={trackCustomEvent}
        />
      ) : null}
      <div id="poynt-collect" className="poynt-collect"></div>
      {collectLoaded ? (
        <CardCollect
          payLink={payLink}
          order={order}
          product={product}
          setTransaction={setTransaction}
          onCollectError={onCollectError}
          showError={showError}
          charging={charging}
          setCharging={setCharging}
          trackCustomEvent={trackCustomEvent}
        />
      ) : null}
    </div>
  );
};

export default PoyntCollectForm;
